import React, {useEffect, useState} from 'react'
import axios from 'axios'
import PropTypes from 'prop-types'
import { useForm } from "react-hook-form";
import Button from '../shared/button'
import Form, { Input, FormGroup } from '../shared/form'
import {FormWrap} from "./attendance-form.stc";

const centerCoordinates = {
	lat: 34.242904, // Replace with your central latitude
	lon: -6.673187 // Replace with your central longitude
};

const radiusInMeters = 5000; // Replace with your desired radius in meters (e.g., 5 km)

const AttendanceForm = ({ inputStyle, locationCallback }) => {
	const {
		register,
		handleSubmit,
		reset,
		formState: { errors },
	} = useForm({
		mode: "onChange",
	});
	const [serverState, setServerState] = useState({
		submitting: false,
		status: null,
	});

	const handleServerResponse = (ok, msg, form) => {
		setServerState({
			submitting: false,
			status: { ok, msg }
		});
		if (ok) {
			form.reset();
			reset();
		}
	};
	const onSubmit = (data, e) => {
		const form = e.target;
		setServerState({ submitting: true });
		axios({
			method: "post",
			url: "https://salamandre.ma/api.php",
			data
		})
			.then(res => {
				handleServerResponse(true, "تم تسجيل حضوركم بنجاح", form);
			})
			.catch(err => {
				handleServerResponse(false, err.response.data.error, form);
			});
	}
	const [locationAllowed, setLocationAllowed] = useState(false);

	useEffect(() => {
		if (JSON.parse(new URLSearchParams(window.location.search).get('localisationCheck')) === false) {
			setLocationAllowed(true);
			locationCallback(true);
			return;
		}
		if (navigator.geolocation) {
			navigator.geolocation.getCurrentPosition(position => {
				const { latitude, longitude } = position.coords;
				if (isWithinRadius(latitude, longitude)) {
					setLocationAllowed(true);
					locationCallback(true);
				} else {
					locationCallback(false);
					// toast.error("You are not within the allowed area to submit this form.");
				}
			}, error => {
				locationCallback(false);
				// toast.error("Error getting location: " + error.message);
			});
		} else {
			locationCallback(false);
			// toast.error("Geolocation is not supported by this browser.");
		}
	}, []);

	const isWithinRadius = (lat, lon) => {
		const earthRadius = 6371000; // meters

		const dLat = degreesToRadians(lat - centerCoordinates.lat);
		const dLon = degreesToRadians(lon - centerCoordinates.lon);

		const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
			Math.cos(degreesToRadians(centerCoordinates.lat)) * Math.cos(degreesToRadians(lat)) *
			Math.sin(dLon / 2) * Math.sin(dLon / 2);

		const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
		const distance = earthRadius * c;

		return distance <= radiusInMeters;
	};

	const degreesToRadians = (degrees) => {
		return degrees * Math.PI / 180;
	};

	return (
		<FormWrap>
			{locationAllowed && <Form onSubmit={handleSubmit(onSubmit)}>
				<div className="form-row">
					<FormGroup>
						<Input
							type="text"
							id="name"
							placeholder="إسمك"
							padding={"0 10px"}
							mb={"10px"}
							border={"1px solid #ebebec"}
							{...register("name", {
								required: "هذه المعلومة ضرورية",
							})}
							{...inputStyle}
						/>
						{errors.name && <span className="error">{errors.name?.message}</span>}
					</FormGroup>
					<FormGroup>
						<Input
							type="email"
							id="email"
							placeholder="بريدك الإلكتروني"
							padding={"0 10px"}
							mb={"10px"}
							border={"1px solid #ebebec"}
							{...register("email", {
								required: "هذه المعلومة ضرورية",
								pattern: {
									value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
									message: "المرجو ادخال بريد إلكتروني صحيح",
								},
							})}
						/>
						{errors.email && <span className="error">{errors.email?.message}</span>}
					</FormGroup>
					<FormGroup>
						<Input
							type="cin"
							id="cin"
							placeholder="رقم البطاقة الوطنية"
							padding={"0 10px"}
							mb={"10px"}
							border={"1px solid #ebebec"}
							{...register("cin", {
								required: "هذه المعلومة ضرورية"
							})}
						/>
						{errors.cin && <span className="error">{errors.email?.message}</span>}
					</FormGroup>
				</div>
				<div className="form-row">
					<FormGroup>
						<Button type="submit" disabled={serverState.submitting} background={"#f9c113"} padding={"10px"} paddingLeft={"10px"} mt={"20px"}>تسجيل الحضور</Button>
						{serverState.status && (
							<p className={`form-output ${!serverState.status.ok ? "errorMsg" : "success"}`}>
								{serverState.status.msg}
							</p>
						)}
					</FormGroup>
				</div>
			</Form> }
		</FormWrap>
	)
}

AttendanceForm.propTypes = {
	inputStyle: PropTypes.object,
	textareaStyle: PropTypes.object
}

AttendanceForm.defaultProps = {
	inputStyle: {
		responsive: {
			xsmall: {
				mb: '20px'
			}
		}
	},
	textareaStyle: {
		mt: '40px',
		mb: '40px',
		responsive: {
			xsmall: {
				mt: '25px',
				mb: '25px'
			}
		}
	}
}

export default AttendanceForm
