import React from 'react';
import PropTypes from "prop-types";
import Layout from "../containers/layout/layout";
import Seo from "../components/seo";
import AttendanceForm from "../containers/attendance-form";


function EventAttendance() {
    return (
        <Layout lang="ar" url="/contact" headerStyle="darkHeader" hideFooter={true}>
            <Seo lang="ar" title="تسجيل الحضور" />
            <AttendanceForm />
        </Layout>
    );
}

EventAttendance.propTypes = {
    HeadingStyle: PropTypes.object,
    TextStyle: PropTypes.object
}

EventAttendance.defaultProps = {
    FormContainer: {
        background: '#fff',
        padding: '20px',
        borderRadius: '10px',
        boxShadow: '0 0 10px rgba(0,0,0,0.1)',
        width: '300px'
    },
    TextStyle: {
        lineHeight: '26px'
    },
    ProjectTypeHeading: {
        as: 'h6',
        color: 'primary',
        fontSize: '14px',
        fontweight: 700,
        mb: '12px'
    },
    ProjectTypeText: {
        m: 0,
        paddingRight: '10px',
        fontSize: '13px',
        color: '#000000'
    }
}

export default EventAttendance;
