import React, {useState} from 'react';
import PropTypes from 'prop-types'
import Heading from '../../components/shared/heading'
import Text from '../../components/shared/text'
import {AttendanceSectionWrap, AttendanceWrap, AttendanceFormWrap } from "./attendance-form.stc";
import AttendanceForm from "../../components/attendance-form";


const AttendanceFormSection = ({ headingStyle, textStyle, lang }) => {

	const [locationAllowed, setLocationAllowed] = useState(true);

	return (
		<AttendanceSectionWrap>
			<div className="row align-items-center">
				<div className="col-2 offset-1">
					<AttendanceWrap>
						<div className="rn-project-meta-inner">
						</div>
						<AttendanceFormWrap>
							<Text {...textStyle}>مرحبا بكم معنا</Text>
							{locationAllowed && <Heading {...headingStyle}>المرجوا إدخال معلوماتكم لتسجيل حضوركم</Heading>}
							{!locationAllowed && <Heading {...headingStyle}>تسجيل الحضور متاح للأشخاص المتواجدين بعين المكان فقط</Heading>}
							<AttendanceForm lang={lang} locationCallback={(isAllowed) => {setLocationAllowed(isAllowed)}} />
						</AttendanceFormWrap>
					</AttendanceWrap>
				</div>
			</div>
		</AttendanceSectionWrap>
	)
}

AttendanceFormSection.propTypes = {
	headingStyle: PropTypes.object,
	textStyle: PropTypes.object
}

AttendanceFormSection.defaultProps = {
	headingStyle: {
		fontSize: '40px',
		lineHeight: '55px',
		color: 'primary',
		fontweight: 600,
		responsive: {
			medium: {
				fontSize: '24px',
				lineHeight: 'initial'
			}
		}
	},
	textStyle: {
		as: 'span',
		color: '#000000',
		fontweight: 500,
		fontSize: '16px',
		texttransform: 'uppercase',
		letterspacing: '1px',
		display: 'inline-block',
		mb: '17px',
		lineHeight: 1,
		responsive: {
			medium: {
				fontSize: '12px',
				mb: '10px',
			}
		}
	}
}

export default AttendanceFormSection;
